<template>
  <div class="contenedor">
    <h1 class="title-vista">{{ $t("messages.aboutUs") }}</h1>
    <p style="text-align: justify;">
      {{
        $i18n.locale === "en"
          ? "Misty Oracle is a team formed by Ligia Lasa and Beatriz Rojas, blood cousins, and soul sisters. This project was born as a result of a spiritual union between the two, in which they have grown together and discovered the common interest of creating more harmony in our world. Despite pursuing different careers, they discovered a shared interest in spirituality and decided to focus on it instead."
          : "Misty Oracle es un equipo conformado por Ligia Lasa y Beatriz Rojas, primas de sangre y hermanas del alma. Este proyecto nace como consecuencia de una unión espiritual de las dos, en la que han crecido juntas y han descubierto el interés común de crear más armonía en nuestro mundo. A pesar de seguir carreras distintas, descubrieron un interés compartido en la espiritualidad y decidieron enfocarse en ello."
      }}
    </p>

    <div class="image-container">     
      <div class="container-individuales">
          <img class="image-item-individual" src="../../assets/imgs/ligia.jpg" alt=""/>
          <img class="image-item-individual" src="../../assets/imgs/beatriz.jpg" alt=""/>
      </div>       
    </div>

    <p style="text-align: justify;">
      {{
        $i18n.locale === "en"
          ? "Ligia completed a degree in Business Administration, but felt unfulfilled working as an accountant. She followed her passion for magic and esoteric topics, exploring Tarot cards and meditation."
          : "Ligia completó sus estudios en Administración de Empresas, pero se sentía insatisfecha trabajando como contable. Siguió su pasión por la magia y temas esotéricos, explorando el Tarot y la meditación."
      }}
    </p>

    <p style="text-align: justify;">
      {{
        $i18n.locale === "en"
          ? "Beatriz studied Materials Engineering and specialized in Metallurgy. While working in research and science, she realised she lacked a strong spiritual foundation to cope with life's chaos and uncertainty. This realisation prompted her to reinvent herself and pursue training in Akashic Records for a deeper spiritual awakening."
          : "Por otro lado, Beatriz estudió Ingeniería de Materiales y se especializó en Metalurgia. Mientras trabajaba en investigación y ciencia, se dio cuenta de que necesitaba una base espiritual más sólida para enfrentar el caos e incertidumbre de la vida. Esta revelación la llevó a reinventarse y buscar formación en Registros Akáshicos para un despertar espiritual más profundo."
      }}
    </p>

    <div class="image-container">
      <div class="container-juntas">
        <img class="image-item" src="../../assets/imgs/juntas1.jpg" alt="" style="margin-bottom: 20px;" />
        <img class="image-item" src="../../assets/imgs/juntas2.jpg" alt="" />
      </div>      
    </div>

    <p style="text-align: justify;">
      {{
        $i18n.locale === "en"
          ? "Together, Ligia and Beatriz aim to heal themselves and guide others to awaken their inner master. They believe that everyone can connect with their life mission and achieve light and fullness. If you're on a spiritual path, they're here to support you and offer their community."
          : "Juntas, Ligia y Beatriz tienen como objetivo sanarse a sí mismas y guiar a otros en el despertar de su maestro interior. Creen que todos pueden conectar con su misión de vida y alcanzar la luz y plenitud. Si estás en un camino espiritual, están aquí para apoyarte y ofrecerte su comunidad."
      }}
    </p>

    <div
      style="
        display: flex;
        justify-content: space-around;
        margin-bottom: 50px;
        flex-wrap: wrap;
      "
    >
      <div class="image-container">
        <img
          class="image-item"
          src="../../assets/imgs/diploma_1.jpg"
          alt="diploma 1"
        />
      </div>

      <div class="image-container">
        <img
          class="image-item"
          src="../../assets/imgs/diploma_4.jpg"
          alt="diploma 4"
        />
      </div>

      <div class="image-container">
        <img
          class="image-item"
          src="../../assets/imgs/diploma_2.jpg"
          alt="diploma 2"
        />
      </div>

      <div class="image-container">
        <img
          class="image-item"
          src="../../assets/imgs/diploma_3.jpg"
          alt="diploma 3"
        />
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: "QuienesSomos",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.contenedor {
  padding: 50px 300px;
  width: 100%;
}

.title {
  font-size: 4rem;
}

p {
  font-size: 1.4rem;
  margin-top: 50px;
  text-align: left;
}

cite {
  font-size: 1.5rem;
  font-weight: bold;
}

.resalte {
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #eb1090;
  text-align: center;
}

.resalteSoft {
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}

li {
  font-size: 1.4rem;
  text-align: left;
}

.link-audio {
  cursor: pointer;
  color: var(--primary-misty-oracle);
  transition: 0.5s all;
  margin-top: 50px;
}

.link-audio:hover {
  color: var(--color-font-misty-oracle);
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.image-item {
  width: auto;
  height: 300px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-left: 50px;
}

.image-item-individual {
  width: auto;
  height: 300px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin-left: 30px
}

.container-juntas{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.container-individuales{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .contenedor {
    padding: 50px 15px;
  }

  .title {
    font-size: 2rem !important;
  }

  p {
    font-size: 1.2rem;
    margin-top: 30px;
  }

  li {
    font-size: 1.2rem;
  }

  cite {
    font-size: 1.3rem;
  }

  .resalte {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .resalteSoft {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 750px) {
  .image-item-individual {
    width: 45%;
    height: auto;
    margin-left: 0px;
  }

}
@media screen and (max-width: 700px) {
  .image-item {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-bottom: 20px;
  }

}
</style>
